<template>
  <div class="icon-picker">
    <button
      aria-label="icon picker"
      @click="showPicker = !showPicker">
      <v-icon
        :class="['icon', `${colorName}-color`]">
        {{ icon }}
      </v-icon>
    </button>
    <transition name="slide-y-transition">
      <v-card
        v-if="showPicker"
        v-click-outside="{
          handler: closePicker,
          include: getClickOutsideIncluded,
        }"
        class="mt-2 pa-0 icon-picker__card"
        max-width="250">
        <v-card-text
          data-cy="picker-card"
          class="icon-picker__icons-list">
          <button
            v-for="(iconName, index) in $static.icons"
            :key="index"
            class="icon-picker__icon"
            @click="$emit('update:icon', iconName)">
            <v-icon color="primary">
              {{ iconName }}
            </v-icon>
          </button>
        </v-card-text>

        <v-divider class="mb-2"/>

        <v-card-text class="px-2 py-0">
          <v-color-picker
            v-model="selectedColor"
            data-cy="color-picker"
            hide-canvas
            hide-inputs
            hide-mode-switch
            hide-sliders
            show-swatches
            :swatches="swatches"
            @input="onColorSelect"/>
        </v-card-text>
      </v-card>
    </transition>
  </div>
</template>

<script>
import {
  ICONS,
  ENV_PRESETS,
  DEFAULT_COLOR,
  DEFAULT_ICON,
  DEFAULT_COLOR_NAME,
} from '@/utils/config/icons'

export default {
  name: 'CyInputsIconPicker',
  props: {
    colorName: {
      type: String,
      default: DEFAULT_COLOR_NAME,
    },
    icon: {
      type: String,
      default: DEFAULT_ICON,
    },
    envCanonical: {
      type: String,
      default: '',
    },
  },
  data: () => ({
    showPicker: false,
    selectedColor: DEFAULT_COLOR,
  }),
  computed: {
    $static: () => ({
      icons: ICONS,
    }),
    swatches () {
      return _.uniqBy(ENV_PRESETS, 'background').map((color) => [color.background])
    },
    colorItem () {
      return _.find(ENV_PRESETS, { name: this.colorName })
    },
  },
  watch: {
    envCanonical (value) {
      const envPreset = _.find(ENV_PRESETS, { name: value })
      if (envPreset) {
        this.$emit('update:icon', envPreset.icon)
        this.$set(this, 'selectedColor', envPreset.background)
        this.onColorSelect()
      }
    },
  },
  created () {
    if (!ICONS.includes(this.icon)) {
      this.$emit('update:icon', DEFAULT_ICON)
    }

    if (!this.colorItem) {
      this.$emit('update:colorName', DEFAULT_COLOR_NAME)
    } else {
      this.selectedColor = this.colorItem.background
    }
  },
  methods: {
    closePicker () {
      this.showPicker = false
    },
    onColorSelect () {
      const newColor = _.find(ENV_PRESETS, { background: this.selectedColor })
      this.$emit('update:colorName', newColor.name || DEFAULT_COLOR_NAME)
    },
    getClickOutsideIncluded () {
      return [document.querySelector('.icon')]
    },
  },
}
</script>

<style lang="scss" scoped>
.icon-picker {
  .icon-picker__icons-list {
    display: flex;
    flex-wrap: wrap;
    padding: $spacer-2;
    gap: $spacer-2;
  }

  .icon-picker__icon {
    padding: $spacer;

    > i {
      width: 24px;
    }
  }
}

::v-deep v-icon {
  color: map.get($slate-grey, "light-1") !important;
}

::v-deep .v-color-picker {
  &__swatches {
    overflow-y: visible;

    > div {
      flex-wrap: nowrap;
      padding: 0;
    }
  }

  &__swatch {
    margin-bottom: 6px;
  }

  &__color {
    width: 32px;
    height: 32px;
    max-height: 32px;
    border-radius: 4px;
  }
}

::v-deep .v-card {
  z-index: 10;
}
</style>
