import { render, staticRenderFns } from "./CyDangerZone.vue?vue&type=template&id=bddd6e74&scoped=true"
import script from "./CyDangerZone.vue?vue&type=script&lang=js"
export * from "./CyDangerZone.vue?vue&type=script&lang=js"
import style0 from "./CyDangerZone.vue?vue&type=style&index=0&id=bddd6e74&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "bddd6e74",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VSwitch } from 'vuetify/lib/components/VSwitch';
installComponents(component, {VDivider,VSwitch})
