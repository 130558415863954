<template>
  <div class="danger-zone">
    <div class="danger-zone__title">
      <h3 class="h5">
        {{ $t('dangerZone') }}
      </h3>
    </div>
    <div
      class="danger-zone__content"
      data-cy="content">
      <div
        v-if="disableValue !== null"
        class="danger-zone__disable-section">
        <div class="disable-section">
          <div
            class="disable-section__title"
            data-cy="disable-title">
            {{ disableValue ? $t('forms.btnEnable') : $t('forms.btnDisable') }}
          </div>
          <div
            class="disable-section__text"
            data-cy="disable-text">
            {{ disableValue ? enableText : disableText }}
          </div>
        </div>
        <div class="disable__action">
          <v-switch
            color="secondary"
            hide-details
            class="mt-0 pt-0"
            data-cy="disable-switch"
            :disabled="disabled"
            :input-value="disableValue"
            @change="$emit('change-disabled', !$event)"/>
        </div>
      </div>

      <v-divider v-if="disableValue !== null"/>

      <div class="danger-zone__delete-section">
        <div class="delete-section pr-2">
          <div
            class="delete-section__title"
            data-cy="delete-title">
            {{ deleteTitle }}
          </div>
          <div
            class="delete-section__text"
            data-cy="delete-text">
            {{ deleteText }}
          </div>
        </div>
        <div class="delete__action">
          <CyButton
            icon="delete"
            variant="secondary"
            theme="error"
            class="ml-4"
            data-cy="delete-button"
            :disabled="disabled"
            :loading="deleting"
            @click="$toggle.showDeleteDialog(true)">
            {{ _.isEmpty(deleteButtonText) ? $t('forms.btnDelete', { entityType }) : deleteButtonText }}
          </CyButton>
        </div>
      </div>
    </div>
    <CyModal
      v-if="showDeleteDialog"
      :header-title="$t('confirmDeleteHeader', { entityType })"
      :action-btn-func="onDeleteConfirm"
      :cancel-btn-func="onCancel"
      data-cy="confirm-modal"
      modal-type="delete"
      small>
      <slot name="confirmText">
        <p v-html="$sanitizeHtml($t('confirmDeleteText', { name: entityName, entityType }))"/>
      </slot>
    </CyModal>
  </div>
</template>

<script>
import i18n from '@/utils/plugins/i18n'

export default {
  name: 'CyDangerZone',
  props: {
    disabled: {
      type: Boolean,
      default: false,
    },
    disableValue: {
      type: Boolean,
      default: null,
    },
    disableText: {
      type: String,
      default () {
        return this.$t('disableText')
      },
    },
    deleteButtonText: {
      type: String,
      default: '',
    },
    deleteText: {
      type: String,
      default () {
        return this.$t('deleteText')
      },
    },
    deleteTitle: {
      type: String,
      default: () => i18n.t('forms.btnDelete'),
    },
    enableText: {
      type: String,
      default () {
        return this.$t('enableText')
      },
    },
    entityType: {
      type: String,
      required: true,
    },
    entityName: {
      type: String,
      required: true,
    },
  },
  data: () => ({
    deleting: false,
    showDeleteDialog: false,
  }),
  methods: {
    onCancel () {
      this.$toggle.showDeleteDialog(false)
    },
    onDeleteConfirm () {
      this.deleting = true
      this.$emit('delete')
      this.$toggle.showDeleteDialog(false)
    },
  },
  i18n: {
    messages: {
      en: {
        dangerZone: 'Danger zone',
        deleteText: 'This is a permanent action that cannot be undone.',
        disableText: 'Temporary action which is quickly reversible.',
        enableText: 'Enable back {entityType}',
        confirmDeleteHeader: 'Delete {entityType}',
        confirmDeleteText: '<b>{name}</b> {entityType} is going to be deleted.',
      },
      es: {
        dangerZone: 'Zona peligrosa',
        deleteText: 'Esta es una acción permanente que no se puede deshacer.',
        disableText: 'Acción temporal fácilmente reversible.',
        enableText: 'Vuelve a abilitar {entityType}',
        confirmDeleteHeader: 'Borrar el {entityType}',
        confirmDeleteText: 'El {entityType} <b>{name}</b> se eliminará.',
      },
      fr: {
        dangerZone: 'Zone de danger',
        deleteText: `C'est une action permanente qui ne peut pas être annulée.`,
        disableText: 'Action temporaire et facilement réversible.',
        enableText: 'Activer retour {entityType}',
        confirmDeleteHeader: 'Supprimer le {entityType}',
        confirmDeleteText: 'Le {entityType} <b>{name}</b> va être supprimé.',
      },
    },
  },
}
</script>

<style lang="scss" scoped>
  .danger-zone {
    &__content {
      border: 1px solid cy-get-color("error");
      border-radius: 4px;
    }

    &__disable-section,
    &__delete-section {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 16px 24px;
    }
  }

  .disable-section,
  .delete-section {
    &__title {
      margin-bottom: 8px;
      color: cy-get-color("primary");
      font-weight: $font-weight-bold;
    }
  }
</style>
